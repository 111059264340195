@media (max-width: 768px) {
    .notes-list {
      flex-wrap: nowrap;
    }
    .note-popup {
      max-width: 100%;
      max-height: 100%;
    }
  }


  @media (min-width: 576px) {
    .note-card {
      width: 50%;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .note-card {
      width: 33.33%;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .note-card {
      width: 25%;
    }
  }

  
  
  
  
  
  
  
.notes-list {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
 .app-main{
   
        background-image: url('D:\indi\src\indi.png');
        background-repeat: repeat;
        background-size: contain;
     
 } 
 
.notes-container::-webkit-scrollbar {
    height: 8px;
  }
  
  .notes-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .notes-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  .notes-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 5px;
    color:  rgb(248, 233, 21);
  }
  .click{
    text-align: left;
    font-weight: bold;
    color:  red;
  }
  .notes-container {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;
    max-width: 100%;
    margin-bottom: 20px;
    display: flex;
   
  }
  
  
  .note-card {
    flex: 0 0 auto;
    width: 300px;
    margin-right: 10px;
    padding: 20px;
    background-color: #e7cbe9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  
  .note-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .note-content {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .note-text {
    white-space: pre-wrap;
  }
  
  .app-main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .app {
    text-align: center;
    background-color: #fafafa;
    font-family: Arial, sans-serif;
  }
  
  .app-header {
    padding: 20px;
    background-color: #333;
    color: #fff;
  }
  
  
  
  
.note-card {
    /* Existing styles */
    /* ... */
  
    transition: transform 0.3s ease;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .note-card:hover {
    transform: scale(1.05);
    background-color: #e0e0e0;
  }
  /* Existing styles */
  .note-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow: auto;
    padding-top:100px;
  }
  
  .note-popup-content {
    background-color: #fff;
    width: 90%;
    height: 90%;
    max-width: 600px;
    max-height: 800px;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    padding-right: 50px;
  }
  
.note-details-title {
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.note-details-content {
  color: #666;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: left;
  white-space: pre-wrap;
}
.note-popup::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 4px;
}
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    font-weight: bold;
    /* background-color: transparent; */
    border: none;
    cursor: pointer;
    margin-top: 50px;
    z-index: 99999; /* Increase the z-index value to ensure the close button is on top */
    background-color: #f9f9f9;
    color: #333;
   
    /* padding: 10px;  */
    padding: top -100px;;
    padding: right -50px;
    

  }
  
  .close-button:hover {
    color: #ff5555;
  }